export const facebookMixin = {
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    ad_accounts() {
      return this.$store.state.facebook.ad_accounts;
    },
    facebook_campaigns() {
      return this.$store.state.facebook.facebook_campaigns;
    },
    latest_campaign() {
      return this.$store.getters["facebook/latest_campaign"];
    },
    running_campaigns() {
      return this.$store.state.facebook.facebook_campaigns.filter(
        (campaign) => campaign.effective_status == "ACTIVE"
      );
    },
    mediapal_social_campaigns() {
      return this.$store.state.facebook.mediapal_social_campaigns;
    },
    paused_campaigns() {
      return this.$store.state.facebook.facebook_campaigns.filter(
        (campaign) => campaign.effective_status == "PAUSED"
      );
    },
    finished_campaigns() {
      return this.$store.state.facebook.facebook_campaigns.filter(
        (campaign) => campaign.effective_status == "ARCHIVED"
      );
    },
    draft_campaigns() {
      return this.$store.state.facebook.facebook_campaigns.filter(
        (campaign) => campaign.effective_status == "IN_PROCESS"
      );
    },
  },
  mounted() {
    this.getAdAccounts();
  },
  methods: {
    async fetchCampaigns(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/getFacebookCampaigns",
          id
        );
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      }
    },
    async setMediapalSocialCampaigns() {
      try {
        this.loading = true;
        await this.$store.dispatch("facebook/getMediaPalSocialCampaigns");
        this.loading = false;
        if (this.ad_accounts)
        {
          this.setAdAccountId(this.ad_accounts[0].id)
        }
        
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      }
    },
    // Get Adaccounts
    async getAdAccounts() {
      try {
        this.loading = true;
        await this.$store.dispatch("facebook/getFacebookAdaccounts");
        this.loading = false;
      } catch (error) {
        this.error = error
        this.loading = false;
      }
    },
    async setAdAccountId(ad_account_id) {
      let selected = this.ad_accounts.filter((account) => account.id === ad_account_id);
      this.ad_account = selected ? selected[0] : this.ad_accounts[0];
      let id = ad_account_id.includes("act_") ? ad_account_id.substring(4) : ad_account_id;
      await this.fetchCampaigns(id);
    },
    clearError() {
      this.error = "";
    },
  },
  watch: {
    ad_accounts() {
      let first_ad_account = this.ad_accounts[0];

      this.ad_account = first_ad_account;

      const ad_account_id = first_ad_account.id.includes("act_") ? first_ad_account.id.substring(4) : first_ad_account.id;

      this.fetchCampaigns(ad_account_id);

    },

    tab() {
      if (this.tab === 'social') {
        this.setMediapalSocialCampaigns();
      }
    },

    facebookTab(){
      if (this.facebookTab === 5) {
        this.setMediapalSocialCampaigns();
      }
    }
  },
};
