<template>
  <v-card outlined>
    <v-card-title>
      <v-row>
        <v-col>
          <h5>Facebook Ads</h5>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4" v-if="facebook_campaigns">
          <v-select
            v-if="!is_adset"
            :items="facebook_campaigns"
            label="Select Campaign"
            outlined
            dense
            item-text="name"
            v-model="selectedCampaign"
            item-value="id"
            :menu-props="{ bottom: true, offsetY: true }"
            @change="onChange($event)"
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-select
            :items="ad_accounts"
            label="Change ad account"
            dense
            outlined
            v-model="adAccountId"
            item-text="name"
            return-object
            v-if="$route.name == 'facebook-ads'"
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="ads"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      :loading="loading"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: 'facebook-ad',
            params: { id: item.id, account_id: item.account_id },
          }"
          ><span class="font-weight-bold"
            >{{ item.name }}git
          </span></router-link
        >
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.effective_status="{ item }">
        <v-chip
          :color="setStatusColor(item.effective_status)"
          :text-color="setTextStatusColor(item.effective_status)"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>
          <span class="text-capitalize">{{
            item.effective_status.replaceAll("_", " ").toLowerCase()
          }}</span>
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.created_time="{ item }">
        {{ new Date(item.created_time).toDateString() }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.targeting="{ item }">
        {{ item.targeting.age_min }} - {{ item.targeting.age_max }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.bid_type="{ item }">
        {{ item.bid_type.replaceAll("_", " ") }}
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions class="d-flex align-center pa-4">
      <div class="caption mr-3">
        {{ `Page: ` + page + ` of ` + pageCount }}
      </div>
      <v-select
        v-model="itemsPerPage"
        :items="[10, 25, 50, 100, 250]"
        label="Items per page"
        outlined
        dense
        hide-details
        @input="itemsPerPage = parseInt($event, 10)"
        class="shrink"
        :menu-props="{ top: true, offsetY: true }"
      ></v-select>

      <v-spacer></v-spacer>

      <v-pagination
        v-model="page"
        :length="pageCount"
        class="custom"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    selectedCampaign: null,
    is_adset: false,
    adAccountId: "",
  }),
  props: {
    ads: {
      type: Array,
    },
    facebook_campaigns: {
      type: Array,
      default: null,
    },
    headers: {
      type: Array,
    },
    ad_accounts: {
      type: Array,
    },
    ad_account_id: {
      type: String,
    },
    selected_facebook_campaign_id: {
      type: String,
    },
    latest_ad_account: {
      type: Object,
    },
    loading: Boolean,
  },
  watch: {
    // ad_account_id() {
    //   this.adAccountId = this.ad_account_id;
    // },
    adAccountId() {
      //set account id on local session
      localStorage.setItem("ad_account", JSON.stringify(this.adAccountId));
      let id = this.adAccountId ? this.adAccountId.id : null;
      if (id) {
        const ad_account_id = id.includes("act_") ? id.substring(4) : id;

        this.$emit("emitAdAccountId", ad_account_id);
      }
    },
    selected_facebook_campaign_id() {
      this.selectedCampaign = this.selected_facebook_campaign_id;
    },
  },
  mounted() {
    this.$route.params.id ? (this.is_adset = true) : null;

    if (typeof localStorage.getItem("ad_account") != undefined) {
      this.adAccountId = JSON.parse(localStorage.getItem("ad_account"));
    }
    //check if local storage has ad account
    // if (localStorage.getItem("ad_account")) {
    //  this.adAccountId = JSON.parse(localStorage.getItem("ad_account"));
    // }

    //outdated logic
    // this.adAccountId = this.latest_ad_account
    //   ? await this.latest_ad_account.id.substring(4)
    //   : "";
  },
  methods: {
    onChange(id) {
      this.$emit("updateCampaignID", id);
    },
    setStatusColor(status) {
      if (status === "WITH_ISSUES") return "red lighten-5";
      if (status === "IN_PROCESS") return "orange lighten-5";
      if (status === "PAUSED") return "blue lighten-5";
      if (status === "CAMPAIGN_PAUSED") return "blue lighten-5";
      if (status === "ACTIVE") return "green lighten-5";
      if (status === "ARCHIVED") return "grey lighten-5";
    },
    setTextStatusColor(status) {
      if (status === "WITH_ISSUES") return "red ";
      if (status === "IN_PROCESS") return "orange ";
      if (status === "PAUSED") return "blue ";
      if (status === "CAMPAIGN_PAUSED") return "blue";
      if (status === "ACTIVE") return "green ";
      if (status === "ARCHIVED") return "grey ";
    },
  },
};
</script>
