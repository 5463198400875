<template>
  <ads-table
    :headers="headers"
    :ads="facebook_ads"
    :facebook_campaigns="facebook_campaigns"
    @updateCampaignID="getAdsByCampaign($event)"
    :selected_facebook_campaign_id="campaignId"
    class="mt-7"
    :ad_accounts="ad_accounts"
    :ad_account_id="ad_account_id_"
    @emitAdAccountId="fetchAdAccountId($event)"
    :loading="loading"
  />
</template>

<script>
import AdsTable from "../../../components/Tables/AdsTable.vue";
import { facebookMixin } from "../../../mixins/facebookMixin";

export default {
  mixins: [facebookMixin],
  data: () => ({
    loading: false,
    adAccountId: "", // 515412839876802
    ad_account_id_: "",
    campaignId: null,
    headers: [
      {
        text: "Ad Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Status",
        value: "effective_status",
      },
      {
        text: "Created Time",
        value: "created_time",
      },
      {
        text: "Age Range",
        value: "targeting",
      },
      {
        text: "Bid Type",
        value: "bid_type",
      },
    ],
  }),
  components: {
    AdsTable,
  },
  computed: {
    facebook_campaigns() {
      return this.$store.state.facebook.facebook_campaigns;
    },
    facebook_ads() {
      return this.$store.state.facebook.facebook_ads;
    },
    selected_facebook_campaignId() {
      return this.$store.state.facebook.selected_facebook_campaignId;
    },
  },
  watch: {
    facebook_campaigns() {
      this.setDefaultCampaign();
    },
    // async latest_ad_account() {
    //   const payload = {};
    //   payload.facebook_adsets_by_campaign = [];
    //   this.$store.commit("facebook/setFacebookAdsetsByCampaign", payload);
    //   this.ad_account_id_ = this.latest_ad_account.id;
    //   this.adAccountId = this.latest_ad_account.id.substring(4);
    //   await this.fetchCampaigns();
    //   await this.setDefaultCampaign();
    // },
    // async ad_account_id() {
    //   const payload = {};
    //   payload.facebook_ads = [];
    //   this.$store.commit("facebook/setFacebookAds", payload);
    //   this.adAccountId = this.ad_account_id.substring(4);
    //   await this.fetchCampaigns();
    //   await this.setDefaultCampaign();
    // },
  },
  methods: {
    fetchAdAccountId(ad_account_id) {
      const payload = {};
      payload.facebook_ads = [];
      this.$store.commit("facebook/setFacebookAds", payload);

      // this.adAccountId = await ad_account_id.substring(4);
      this.adAccountId = ad_account_id;

      if (ad_account_id) {
        this.fetchCampaigns(this.adAccountId);
      }
    },
    setDefaultCampaign() {
      if (this.facebook_campaigns.length > 0) {
        this.selectedCampaign = this.facebook_campaigns[0];
        this.getAdsByCampaign(this.selectedCampaign.id);
      }
    },
    async getAdsByCampaign(id) {
      this.campaignId = id;

      // Get Facebook ads by campaign
      try {
        this.loading = true;
        await this.$store.dispatch("facebook/getFacebookAds", {
          accountId: this.adAccountId,
          campaignId: this.campaignId,
        });
        this.loading = false;
      } catch (error) {
        this.error = error
        this.loading = false;
      }
    },
  },
  beforeMount() {
    let payload = {};
    payload.facebook_ads = [];
    this.$store.commit("facebook/setFacebookAds", payload);
  },
};
</script>
